import React, { Children, Fragment, Component } from 'react'
import PropTypes from 'prop-types'

import { getUniqueId } from '@jsluna/utils'
import AccordionItem from './AccordionItem'

class Accordion extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.state = {
      hasInteracted: false,
      openItems: {},
    }
    this.uuid = `ln-accordion-${getUniqueId()}`
  }

  getChildren() {
    const { title, id, children } = this.props

    if (title) {
      return <AccordionItem {...this.props} id={this.getItemId(id, 0)} />
    }
    return children
  }

  getItemId(itemId, index) {
    return itemId || `${this.uuid}-${index}`
  }

  handleClick(itemId, open) {
    const { multipleOpen } = this.props

    this.setState(state => ({
      hasInteracted: true,
      openItems: multipleOpen
        ? { ...state.openItems, [itemId]: !open }
        : { [itemId]: !open },
    }))
  }

  render() {
    const { openItems, hasInteracted } = this.state
    const {
      multipleOpen,
      titleElement,
      standalone,
      respondAt,
      color,
    } = this.props
    const itemProps = { titleElement, standalone, respondAt, color }

    const children = Children.map(this.getChildren(), (child, index) => {
      const id = this.getItemId(child.props.id, index)

      let open = openItems[id]
      if (!multipleOpen && hasInteracted) {
        open = open || false
      }

      return React.cloneElement(child, {
        ...itemProps,
        id,
        open,
        onClick: isOpen => {
          this.handleClick(id, isOpen)
          if (child.props.onClick) {
            child.props.onClick(!isOpen)
          }
        },
      })
    })

    return <Fragment>{children}</Fragment>
  }
}

Accordion.propTypes = {
  /** Array of `AccordionItems` or body contents if `title` prop set i.e. single accordion mode */
  children: PropTypes.node,
  /** Allow multipleOpen items to be open at one time */
  multipleOpen: PropTypes.bool,
  /** Element to use as title */
  titleElement: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.string,
  ]),
  /** Set to `true` to add spacing between items */
  standalone: PropTypes.bool,
  /** @see [`AccordionItem`](#/Components?id=accordion-item) */
  title: PropTypes.node,
  /** @see [`AccordionItem`](#/Components?id=accordion-item) */
  className: PropTypes.string,
  /** @see [`AccordionItem`](#/Components?id=accordion-item) */
  defaultOpen: PropTypes.bool,
  /** @see [`AccordionItem`](#/Components?id=accordion-item) */
  open: PropTypes.bool,
  /** @see [`AccordionItem`](#/Components?id=accordion-item) */
  id: PropTypes.string,
  /** @see [`AccordionItem`](#/Components?id=accordion-item) */
  onClick: PropTypes.func,
  /** @see [`AccordionItem`](#/Components?id=accordion-item) */
  respondAt: PropTypes.string,
  /** @see [`AccordionItem`](#/Components?id=accordion-item) */
  color: PropTypes.string,
}

Accordion.defaultProps = {
  children: undefined,
  multipleOpen: false,
  titleElement: undefined,
  standalone: false,

  title: undefined,
  className: undefined,
  defaultOpen: false,
  open: undefined,
  id: undefined,
  onClick: undefined,
  respondAt: undefined,
  color: undefined,
}

Accordion.displayName = 'Accordion'

export default Accordion
