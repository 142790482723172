import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ArrowLeft, ArrowRight } from '@jsluna/icons'
import { childMatchesType, filterProps } from '@jsluna/utils'

import TabLink from './TabLink'

const TabList = ({
  className,
  children,
  type,
  color,
  fill,
  deep,
  showPrevArrow,
  showNextArrow,
  animateDistance,
  getChildren,
  onArrowClick,
  onTabLinkFocus,
  ...rest
}) => {
  const isResponsive =
    !fill && React.Children.count(children) > 1 && onArrowClick
  const transform =
    isResponsive && animateDistance
      ? `translateX(${animateDistance}%)`
      : undefined

  if (getChildren) {
    getChildren(children)
  }

  return (
    <nav
      {...filterProps(rest, ['visibleTabs'])}
      className={classnames(
        'ln-c-tabs',
        className,
        type && `ln-c-tabs--${type}`,
        color && `ln-c-tabs--${color}`,
        {
          'ln-c-tabs--fill': fill,
          'ln-c-tabs--deep': deep,
          'has-prev-arrow': showPrevArrow,
          'has-next-arrow': showNextArrow,
        },
      )}
    >
      <div className="ln-c-tabs__list-wrap">
        <div className="ln-c-tabs__list" role="tablist" style={{ transform }}>
          {isResponsive
            ? React.Children.map(children, (child, index) => {
                const props = {
                  key: index,
                }

                if (childMatchesType(child, TabLink)) {
                  props.onFocus = onTabLinkFocus
                }

                return React.cloneElement(child, props)
              })
            : children}
        </div>
        {isResponsive && [
          <button
            type="button"
            key="arrowPrev"
            className="ln-c-tabs__link ln-c-tabs__link--arrow ln-c-tabs__link--arrow-prev"
            tabIndex="-1"
            onClick={() => onArrowClick(false)}
            aria-hidden
          >
            <ArrowLeft />
          </button>,
          <button
            type="button"
            key="arrowNext"
            className="ln-c-tabs__link ln-c-tabs__link--arrow ln-c-tabs__link--arrow-next"
            tabIndex="-1"
            onClick={() => onArrowClick(true)}
            aria-hidden
          >
            <ArrowRight />
          </button>,
        ]}
      </div>
    </nav>
  )
}

TabList.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /** Take up full width of container with space split between links evenly */
  fill: PropTypes.bool,
  /** Use larger variant of TabLink */
  deep: PropTypes.bool,
  /** Use different TabLink style */
  type: PropTypes.oneOf(['pill']),
  /** Use a different colour theme variant */
  color: PropTypes.oneOf(['light', 'dark']),
  showPrevArrow: PropTypes.bool,
  showNextArrow: PropTypes.bool,
  animateDistance: PropTypes.number,
  getChildren: PropTypes.func,
  onArrowClick: PropTypes.func,
  onTabLinkFocus: PropTypes.func,
}

TabList.defaultProps = {
  className: undefined,
  fill: false,
  deep: false,
  type: undefined,
  color: undefined,
  showPrevArrow: false,
  showNextArrow: false,
  animateDistance: 0,
  getChildren: undefined,
  onArrowClick: undefined,
  onTabLinkFocus: undefined,
}

TabList.displayName = 'TabList'

export default TabList
