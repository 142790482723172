import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TabLink = ({
  element,
  active,
  children,
  className,
  onClick,
  onFocus,
  ...rest
}) => {
  const tabEl = element

  const props = {
    ...rest,
    className: classnames('ln-c-tabs__link', className, {
      'is-active': active,
    }),
    role: 'tab',
    'aria-selected': active,
    onClick,
    onFocus,
  }

  return React.isValidElement(tabEl)
    ? React.cloneElement(tabEl, props, children)
    : React.createElement(tabEl, props, children)
}

TabLink.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
}

TabLink.defaultProps = {
  element: 'button',
  active: false,
  className: undefined,
  onClick: undefined,
  onFocus: undefined,
}

TabLink.displayName = 'TabLink'

export default TabLink
