import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { filterProps } from '@jsluna/utils'

import { ALL_TYPES } from './constants'

const PaginationItem = ({
  children,
  type,
  className,
  page,
  onClick,
  href,
  disabled,
  element,
  current,
  label,
  ...rest
}) => {
  let Link = element
  const linkProps = {
    ...rest,
    className: classnames('ln-c-pagination__link', className, {
      'is-disabled': disabled,
    }),
    onClick: onClick ? e => onClick(page, e) : undefined,
    disabled,
    page,
    'aria-label': label,
    'aria-current': current ? true : undefined,
  }

  if (disabled) {
    Link = 'button'
    linkProps.type = 'button'
    linkProps['aria-disabled'] = true
  }

  if (Link === 'a') {
    linkProps.href = href
  }

  const listItemClassName = classnames(
    'ln-c-pagination__item',
    `ln-c-pagination__item--${type}`,
    current && 'is-current',
  )

  return (
    <li className={listItemClassName}>
      <Link {...filterProps(linkProps, ['page'])}>{children}</Link>
    </li>
  )
}

PaginationItem.displayName = 'PaginationItem'

PaginationItem.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(ALL_TYPES).isRequired,
  className: PropTypes.string,
  page: PropTypes.number,
  current: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
  ]),
}

PaginationItem.defaultProps = {
  className: undefined,
  page: undefined,
  current: false,
  onClick: undefined,
  href: undefined,
  disabled: false,
  element: 'a',
}

export default PaginationItem
