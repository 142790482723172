import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ListItem } from '@jsluna/list'

const BreadcrumbsItem = ({ children, active, className, ...rest }) => {
  const linkProps = {
    className: 'ln-c-breadcrumbs__link',
    'aria-current': active ? 'page' : undefined,
  }

  return (
    <ListItem
      {...rest}
      type="inline"
      className={classnames(
        'ln-c-breadcrumbs__item',
        active && 'ln-c-breadcrumbs__item--active',
        className,
      )}
    >
      {React.Children.count(children) === 1 &&
      React.isValidElement(children) ? (
        React.cloneElement(React.Children.only(children), linkProps)
      ) : (
        <span {...linkProps}>{children}</span>
      )}
    </ListItem>
  )
}
BreadcrumbsItem.displayName = 'BreadcrumbsItem'

BreadcrumbsItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  active: PropTypes.bool,
}

BreadcrumbsItem.defaultProps = {
  active: false,
  className: undefined,
}

export default BreadcrumbsItem
