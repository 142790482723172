import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { List } from '@jsluna/list'

const baseClassName = 'ln-c-breadcrumbs'

const BreadcrumbsWrapper = ({
  element,
  children,
  className,
  narrow,
  light,
  backToParent,
  color,
  ...rest
}) => {
  const Element = element

  return (
    <Element {...rest} aria-label="Breadcrumb">
      <List
        className={classnames(
          `${baseClassName}`,
          backToParent && `${baseClassName}--back-to-parent`,
          narrow && `${baseClassName}--narrow`,
          light && `${baseClassName}--light`,
          color && `${baseClassName}--${color}`,
          className,
        )}
        type="inline"
        ordered
      >
        {children}
      </List>
    </Element>
  )
}
BreadcrumbsWrapper.displayName = 'BreadcrumbsWrapper'

BreadcrumbsWrapper.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  /** Show only the latest parent crumb on smaller screens */
  narrow: PropTypes.bool,
  /** @deprecated Use the light theme color for nested links */
  light: PropTypes.bool,

  /** Show only the latest parent crumb */
  backToParent: PropTypes.bool,
  /** Colour theme to be used */
  color: PropTypes.string,
}

BreadcrumbsWrapper.defaultProps = {
  element: 'nav',
  className: undefined,
  narrow: false,
  light: false,
  backToParent: false,
  color: undefined,
}

export default BreadcrumbsWrapper
