import React from 'react'

import FormOptionField from './FormOptionField'

/**
 * @see Combines [`FormOptionField`](#/Components/Form?id=formoptionfield) and [`FormGroup`](#/Components/Form?id=formgroup-1) for a more feature rich component.
 */
const SwitchField = props => <FormOptionField optionType="switch" {...props} />

SwitchField.displayName = 'SwitchField'

export default SwitchField
