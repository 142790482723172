import React from 'react'
import PropTypes from 'prop-types'

import { calcOrdinal } from './utils'
import { TYPE_PAGE } from './constants'

import PaginationItem from './PaginationItem'

const buildLabel = (page, current) =>
  `${current ? 'Current page, ' : ''}${calcOrdinal(page)} page`

const PaginationPage = ({ page, current, ...rest }) => (
  <PaginationItem
    {...rest}
    type={TYPE_PAGE}
    page={page}
    current={current}
    label={buildLabel(page, current)}
  >
    {page}
  </PaginationItem>
)

PaginationPage.propTypes = {
  page: PropTypes.number,
  current: PropTypes.bool,
}

PaginationPage.defaultProps = {
  page: undefined,
  current: false,
}

PaginationPage.displayName = 'PaginationPage'

export default PaginationPage
