import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ArrowDown } from '@jsluna/icons'
import { filterProps } from '@jsluna/utils'

/**
 * Represents a single item in an accordion.
 * Wrap in [`Accordion`](#/Components/Accordion?id=accordion-1) for state management.
 */
const AccordionItem = ({
  element: Element,
  children,
  className,
  title,
  titleElement: Heading,
  defaultOpen,
  open: propsOpen,
  standalone,
  id,
  onClick,
  respondAt,
  color,
  ...rest
}) => {
  const titleId = id
  const bodyId = `${id}-body`

  const open = propsOpen !== undefined ? propsOpen : defaultOpen

  return (
    <Element
      {...filterProps(rest, ['multipleOpen'])}
      className={classnames(
        !respondAt && 'ln-c-accordion',
        respondAt && `ln-c-accordion@max-${respondAt}`,
        open && 'is-open',
        standalone && 'ln-c-accordion--standalone',
        color && `ln-c-accordion--${color}`,
        className,
      )}
    >
      <div className="ln-c-accordion__head">
        <button
          className="ln-c-accordion__toggle"
          type="button"
          onClick={onClick && (() => onClick(open))}
          aria-controls={bodyId}
          aria-expanded={open}
          aria-labelledby={titleId}
        >
          <ArrowDown className="ln-c-accordion__icon" />
        </button>
        <Heading className="ln-c-accordion__title" id={titleId}>
          {title}
        </Heading>
      </div>
      <div
        className="ln-c-accordion__body"
        role="region"
        aria-labelledby={titleId}
        id={bodyId}
      >
        {(!!respondAt || open) && children}
      </div>
    </Element>
  )
}

AccordionItem.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  titleElement: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.string,
  ]),
  defaultOpen: PropTypes.bool,
  open: PropTypes.bool,
  standalone: PropTypes.bool,
  id: PropTypes.string,
  onClick: PropTypes.func,
  /** Use to only show the content as an accordion until the specified breakpoint is reached */
  respondAt: PropTypes.string,
  color: PropTypes.oneOf(['light', 'dark']),
}

AccordionItem.defaultProps = {
  element: 'div',
  className: undefined,
  titleElement: 'h4',
  defaultOpen: false,
  open: undefined,
  standalone: false,
  id: '',
  onClick: undefined,
  respondAt: undefined,
  color: undefined,
}

AccordionItem.displayName = 'AccordionItem'

export default AccordionItem
