import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Cancel, ArrowDown, ArrowUp } from '@jsluna/icons'
import { Button } from '@jsluna/button'
import {
  Input,
  InputGroup,
  InputControl,
  InputAction,
  InputIcon,
} from '@jsluna/form'
import { onKeyPress } from '@jsluna/utils'

const AutocompleteInputGroup = ({
  getInputProps,
  getToggleButtonProps,
  getClearButtonProps,
  selectedOption,
  selectedOptions,
  isOpen,
  placeholder,
  describedBy,
  onChange,
  getOptionLabel,
  getOptionValue,
  icon,
  action,
  hideInput,
  autoComplete,
  disabled,
  spellcheck,
  name,
  ...rest
}) => {
  const selectedOptionsLength = selectedOptions.length
  const isMultiple = selectedOptionsLength > 0
  const clearButtonProps = getClearButtonProps()

  const handleBackspace = e => {
    if (e.target.value.length > 0 && !selectedOption) {
      return true
    }

    if (isMultiple) {
      return clearButtonProps.onClick(
        selectedOptions[selectedOptionsLength - 1],
      )
    }

    return clearButtonProps.onClick()
  }

  const inputProps = getInputProps({
    onChange,
    onKeyDown: onKeyPress([{ name: 'Backspace', code: 8 }], handleBackspace),
    role: 'textbox',
    disabled,
  })

  return (
    <InputGroup disabled={disabled}>
      {icon && <InputIcon>{icon}</InputIcon>}
      <InputControl>
        <div className={classnames('ln-c-autocomplete', isOpen && 'is-open')}>
          {isMultiple &&
            selectedOptions.map(item => (
              <span
                key={getOptionValue(item)}
                className="ln-c-autocomplete__selected-item"
              >
                {getOptionLabel(item)}
                <button
                  type="button"
                  className="ln-c-autocomplete__clear-selected-item"
                  {...clearButtonProps}
                  onClick={() => clearButtonProps.onClick(item)}
                >
                  <Cancel fixed />
                </button>
              </span>
            ))}
          {!hideInput && (
            <Input
              {...rest}
              className="ln-c-autocomplete__input"
              {...inputProps}
              value={
                !isMultiple && selectedOption
                  ? getOptionLabel(selectedOption)
                  : inputProps.value
              }
              placeholder={placeholder}
              aria-describedby={describedBy}
              autoComplete={autoComplete}
              spellCheck={spellcheck}
              id={name}
              name={name}
            />
          )}
        </div>
      </InputControl>
      {((!hideInput && action) || selectedOption) && (
        <InputAction>
          {selectedOption ? (
            <Button {...clearButtonProps}>
              <Cancel />
            </Button>
          ) : (
            action && (
              <Button {...getToggleButtonProps({ disabled })}>
                {isOpen ? <ArrowUp /> : <ArrowDown />}
              </Button>
            )
          )}
        </InputAction>
      )}
    </InputGroup>
  )
}

AutocompleteInputGroup.propTypes = {
  getInputProps: PropTypes.func.isRequired,
  getToggleButtonProps: PropTypes.func.isRequired,
  getClearButtonProps: PropTypes.func.isRequired,
  selectedOption: PropTypes.shape({}),
  selectedOptions: PropTypes.arrayOf(PropTypes.object),
  isOpen: PropTypes.bool,
  placeholder: PropTypes.string,
  describedBy: PropTypes.string,
  onChange: PropTypes.func,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  icon: PropTypes.node,
  action: PropTypes.bool,
  hideInput: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  spellcheck: PropTypes.bool,
  name: PropTypes.string,
}

AutocompleteInputGroup.defaultProps = {
  selectedOption: undefined,
  selectedOptions: [],
  isOpen: false,
  placeholder: undefined,
  describedBy: undefined,
  onChange: undefined,
  icon: undefined,
  action: true,
  hideInput: false,
  autoComplete: 'off',
  disabled: false,
  spellcheck: false,
  name: undefined,
}

AutocompleteInputGroup.displayName = 'AutocompleteInputGroup'

export default AutocompleteInputGroup
